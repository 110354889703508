import React from 'react'
import {
    Box,
    Flex,
    Link
} from '@chakra-ui/react'
import { Link as GatsbyLink } from 'gatsby'

const MainMenu = () => {
    return (
        <Flex flexDirection="row" 
            borderBottom="8px"
            borderBottomColor="brand.600" 
            justifyContent="space-around"
            mt="2"
            display={{base: 'none', lg: 'Flex'}}>
            <Box p="3">
                <Link as={GatsbyLink} to="/moose-hockey" partiallyActive={true} variant="nav-item" activeClassName="active" className="nav-item" >
                    Moose Hockey
                </Link>
            </Box>
            <Box p="3">
            <Link as={GatsbyLink} to="/programs" partiallyActive={true} variant="nav-item" activeClassName="active" className="nav-item">
                Programs
            </Link>
            </Box>
            <Box p="3">
            <Link as={GatsbyLink} to="/events" partiallyActive={true} variant="nav-item" activeClassName="active">
                Events
            </Link>
            </Box>
            <Box p="3">
            <Link as={GatsbyLink} to="/facilities" partiallyActive={true} variant="nav-item" activeClassName="active">
                Facilities
            </Link>
            </Box>
            <Box p="3">
            <Link as={GatsbyLink} to="/sponsorship" partiallyActive={true} variant="nav-item" activeClassName="active">
                Sponsorship
            </Link>
            </Box>
            <Box p="3">
            <Link as={GatsbyLink} to="/donate" partiallyActive={true} variant="nav-item" activeClassName="active">
                Donate
            </Link>
            </Box>
        </Flex>
    )
}

export default MainMenu