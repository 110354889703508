import React from 'react'
import { Link as GatsbyLink } from 'gatsby'

import {
    Box,
    Flex,
    Link,
    Text,
    HStack,
    Center
} from '@chakra-ui/react'
import styled from '@emotion/styled'
import FacebookIcon from './FacebookIcon'
import InstagramIcon from './InstagramIcon'
import SnowKingLogoVertical from './SnowKingLogoVertical'

const FooterLink = styled(GatsbyLink)`
    color: #221c1e;
    text-transform: uppercase;
    margin-bottom: 0.8rem;
    font-weight: 700;
    :hover {
       color: #cc2027;   
    }
    @media (max-width: 768px) {
        font-size: 0.8rem;
    }
`

const FooterMenu = () => (
    <Box>   
        <Flex justifyContent="space-around" w="100%" flexWrap="wrap">
            <Box>
               <Flex flexDirection="column">
                    <FooterLink to="/moose-hockey" as={GatsbyLink} activeClassName="active">
                        Moose Hockey
                    </FooterLink>
                    <FooterLink to="/programs" as={GatsbyLink} activeClassName="active">
                        Programs
                    </FooterLink>
                    <FooterLink to="/events" as={GatsbyLink} activeClassName="active">
                        Events
                    </FooterLink>
                    <FooterLink to="/facilities" as={GatsbyLink} activeClassName="active">
                        Facilities
                    </FooterLink>
                    <FooterLink to="/donate" as={GatsbyLink} activeClassName="active">
                        Donate
                    </FooterLink>
               </Flex>
            </Box>
            <Box>
               <Flex flexDirection="column">
               <FooterLink to="/sponsorship" as={GatsbyLink} activeClassName="active">
                        Sponsorship
                    </FooterLink>
                    <FooterLink to="/moose-hockey" as={GatsbyLink}>
                        Buy Tickets
                    </FooterLink>
                    <FooterLink to="/moose-hockey" as={GatsbyLink}>
                        Game Schedule
                    </FooterLink>
                    <FooterLink to="/contact" as={GatsbyLink}>
                        Contact Us
                    </FooterLink>
                    {/* <FooterLink to="/covid-protocols" as={GatsbyLink}>
                        COVID Protocols
                    </FooterLink> */}
               </Flex>
            </Box>
            <Box>
               <Flex flexDirection="column">
               <Center my="4" display={{base: 'flex', lg: 'none'}}><SnowKingLogoVertical fill="black" width="50%" /></Center>
                    <Box>
                        <Text mb="1" textAlign={{base: 'center', lg: 'left'}}>
                       <strong> Snow King Sports &amp; Events Center</strong><br />
                        100 E. Snow King Ave.<br />
                        PO Box 10965<br />
                        Jackson, WY 83002<br />
                        (307) 201-1633<br />
                        <a href="mailto:info@snowkingsec.com">info@snowkingsec.com</a>
                        </Text>
                    </Box>
                    <HStack spacing="4" justify={{base: 'center', lg: 'flex-start'}}>
                        <FacebookIcon />
                        <InstagramIcon />
                    </HStack>

               </Flex>
            </Box>
        </Flex>
    </Box>
)

export default FooterMenu