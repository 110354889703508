exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-covid-protocols-js": () => import("./../../../src/pages/covid-protocols.js" /* webpackChunkName: "component---src-pages-covid-protocols-js" */),
  "component---src-pages-donate-js": () => import("./../../../src/pages/donate.js" /* webpackChunkName: "component---src-pages-donate-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-facilities-js": () => import("./../../../src/pages/facilities.js" /* webpackChunkName: "component---src-pages-facilities-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-moose-hockey-js": () => import("./../../../src/pages/moose-hockey.js" /* webpackChunkName: "component---src-pages-moose-hockey-js" */),
  "component---src-pages-moose-hockey-stats-js": () => import("./../../../src/pages/moose-hockey/stats.js" /* webpackChunkName: "component---src-pages-moose-hockey-stats-js" */),
  "component---src-pages-programs-js": () => import("./../../../src/pages/programs.js" /* webpackChunkName: "component---src-pages-programs-js" */),
  "component---src-pages-sponsorship-js": () => import("./../../../src/pages/sponsorship.js" /* webpackChunkName: "component---src-pages-sponsorship-js" */),
  "component---src-pages-stylesheet-js": () => import("./../../../src/pages/stylesheet.js" /* webpackChunkName: "component---src-pages-stylesheet-js" */),
  "component---src-templates-program-page-template-js": () => import("./../../../src/templates/program-page-template.js" /* webpackChunkName: "component---src-templates-program-page-template-js" */)
}

