import React from "react";
import styled from "@emotion/styled";

const Logo = styled.svg`
  width: ${props => props.width ? props.width : '30px' };
  height: auto;
      .icon {
        fill: #cc2027;
      }
      .icon:hover {
          fill: black;
      }
  `

const InstagramIcon = (props) => {
  return (
<a href='https://www.instagram.com/jhmoosehockey/' target="_blank" rel="noopener noreferrer">
<Logo xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 448" {...props}>
  <g id="Layer_2" data-name="Layer 2">
    <g id="Layer_1-2" data-name="Layer 1">
      <path className="icon" d="M224,170.66A53.34,53.34,0,1,0,277.36,224,53.34,53.34,0,0,0,224,170.66Zm124.71-41A54,54,0,0,0,318.3,99.25c-21-8.29-71-6.43-94.3-6.43s-73.25-1.93-94.31,6.43a54,54,0,0,0-30.41,30.41c-8.28,21-6.43,71.05-6.43,94.33S91,297.26,99.32,318.33a54,54,0,0,0,30.41,30.41c21,8.29,71,6.43,94.31,6.43s73.24,1.93,94.3-6.43a54.00014,54.00014,0,0,0,30.41-30.41c8.35-21,6.43-71.05,6.43-94.33s1.92-73.26-6.43-94.33ZM224,306a81.9,81.9,0,1,1,.20012,0Q224.10006,306.00014,224,306Zm85.38-148.3a19.14,19.14,0,1,1,19.13-19.15v.01a19.1,19.1,0,0,1-19.01984,19.17984L309.42,157.74ZM400,0H48A48,48,0,0,0,0,48V400a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V48A48,48,0,0,0,400,0ZM382.88,290c-1.29,25.63-7.14,48.34-25.85,67s-41.4,24.63-67,25.85c-26.41,1.49-105.59,1.49-132,0-25.63-1.29-48.26-7.15-67-25.85S66.4,315.58,65.18,290c-1.49-26.42-1.49-105.61,0-132,1.29-25.63,7.07-48.34,25.85-67s41.47-24.56,67-25.78c26.41-1.49,105.59-1.49,132,0,25.63,1.29,48.33,7.15,67,25.85s24.63,41.42,25.85,67.05C384.37,184.44,384.37,263.56,382.88,290Z"/>
    </g>
  </g>
</Logo>
</a>
  );
};

export default InstagramIcon;
