import React, { useRef } from 'react';
import {
    Box,
    Button,
    useDisclosure,
    Drawer,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    DrawerFooter,
    DrawerHeader,
    DrawerBody,
    Flex,
    Link,
    IconButton
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import { Link as GatsbyLink } from 'gatsby'
import BuyTicketsModal from '../MooseHockeyModals/BuyTicketsModal';
import WatchLiveModal from '../MooseHockeyModals/WatchLiveModal';

const MobileMenu = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = useRef()

    return (
        <>
            <Box mt="2">
                <IconButton ref={btnRef} onClick={onOpen} aria-label="Open menu" variant="outline" icon={<HamburgerIcon />} />
            </Box>
            <Drawer
                isOpen={isOpen}
                placement='right'
                onClose={onClose}
                finalFocusRef={btnRef}
            >
                <DrawerOverlay />
                <DrawerContent>
                <DrawerCloseButton />
                {/* <DrawerHeader>Create your account</DrawerHeader> */}

                <DrawerBody>
                <Flex flexDirection="column" 
                    borderBottom="2px"
                    borderBottomColor="brand.600" 
                    justifyContent="space-around"
                    mt="2"
                >
                     <Box p="3">
                        <Link as={GatsbyLink} to="/" variant="nav-item" activeClassName="active" className="nav-item" onClick={onClose} >
                            Home
                        </Link>
                    </Box>
                    <Box p="3">
                        <Link as={GatsbyLink} to="/moose-hockey" variant="nav-item" activeClassName="active" className="nav-item" onClick={onClose} >
                            Moose Hockey
                        </Link>
                    </Box>
                    <Box p="3">
                    <Link as={GatsbyLink} to="/programs" variant="nav-item" activeClassName="active" className="nav-item" onClick={onClose}>
                        Programs
                    </Link>
                    </Box>
                    <Box p="3">
                    <Link as={GatsbyLink} to="/events" variant="nav-item" activeClassName="active" onClick={onClose}>
                        Events
                    </Link>
                    </Box>
                    <Box p="3">
                    <Link as={GatsbyLink} to="/facilities" variant="nav-item" activeClassName="active" onClick={onClose}>
                        Facilities
                    </Link>
                    </Box>
                    <Box p="3">
                    <Link as={GatsbyLink} to="/sponsorship" variant="nav-item" activeClassName="active" onClick={onClose}>
                        Sponsorship
                    </Link>
                    </Box>
                    <Box p="3">
                    <Link as={GatsbyLink} to="/donate" variant="nav-item" activeClassName="active" onClick={onClose}>
                        Donate
                    </Link>
                    </Box>
                    <Box p="3">
                    <Link as={GatsbyLink} to="/contact" variant="nav-item" activeClassName="active" onClick={onClose}>
                        Contact
                    </Link>
                    </Box>
                </Flex>
                <Flex flexDirection="column" 
            justifyContent="flex-end"
            my="2"
            
            >
                <Box>
                <Flex w="100%"
                    flexDirection="column"
                    justifyContent="space-around"
                    
                     >
                {/* <Box p="3">
                <Link as={GatsbyLink} to="/covid-protocols" variant="top-nav-item">
                    COVID Protocols
                </Link>
            </Box> */}
            <Box p="3">
            <BuyTicketsModal />
            </Box>
            <Box p="3">
            <WatchLiveModal />
            </Box>
                </Flex>
                </Box>
        </Flex>
                </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    )
}

export default MobileMenu;