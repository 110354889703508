import React from 'react'
import { 
    Box,
    Flex,
    Stack,
    HStack,
    Text,
    Center,
    Link
 } from '@chakra-ui/react'
import StripeDivder from '../IceMasks/StripeDivider'
import FooterShape from './FooterShape.svg'
import SnowKingLogoVertical from './SnowKingLogoVertical'
import FooterMenu from './FooterMenu'

const Footer = () => (
  <Box as="footer">

    {/* Mobile Footer */}
    <Box display={{base: 'block', lg: 'none'}}>
      <Box>
        <FooterMenu />
      </Box>
    </Box>

    {/* Desktop Footer */}
    <Flex w="100%" pt="8" pb="4" alignItems="flex-end" display={{base: 'none', lg: 'flex'}}>
      <Box w="40%" position="relative" mr="6" display={{md: 'none', lg: 'block'}}>
        <img src={FooterShape} />
        <Box
          position="absolute"
          top={{ md: "28%", lg: "26%" }}
          left={{ md: "25%", lg: "25%" }}
          w="60%"
        >
          <Flex flexWrap="wrap">
            <Box w={{ md: "55%", lg: "60%" }}>
              <SnowKingLogoVertical width="80%" />
            </Box>
          </Flex>
        </Box>
      </Box>

      <Box w={{md: "100%", lg: "60%"}}>
        <FooterMenu />
      </Box>
    </Flex>
    <Box w="100%" p="2">
      <Text
        color="shadow.900"
        fontSize={{ lg: "1rem" }}
        mb="0"
        textAlign="center"
      >
        Snow King Sports &amp; Events Center is operated by Center Management
        Inc. a Wyoming 501(c)(3) nonprofit public benefit organization.
      </Text>
    </Box>
    <StripeDivder />
    <Flex>
      <Box textAlign="center" w="100%">
        <Text m="1" fontSize="sm">
          &copy; {new Date().getFullYear()} Snow King Sports &amp; Events Center
          | Website by <Link href="https://caldera-creative.com" target="_blank" rel="noopener noreferrer">Caldera Creative</Link>
        </Text>
      </Box>
    </Flex>
  </Box>
);

export default Footer;