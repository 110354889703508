import React from 'react'
import styled from '@emotion/styled'

const Logo = styled.svg`
width: ${props => props.width ? props.width : '275px' };
height: 100%;
margin: ${props => props.margin ? props.margin: 'unset'};
.cls-1, .cls-2 {
        fill: ${props => props.fill ? props.fill : '#fff'};
      }

      .cls-2 {
        fill-rule: evenodd;
      }
`

const SnowKingLogo = (props) => {
  return (
    <Logo xmlns="http://www.w3.org/2000/svg" viewBox="0 0 208.51201 187.68009" {...props}>
  <g id="Layer_2" data-name="Layer 2">
    <g id="Layer_1-2" data-name="Layer 1">
      <g id="Layer_2-2" data-name="Layer 2">
        <g id="Layer_1-2-2" data-name="Layer 1-2">
          <g>
            <g>
              <path class="cls-1" d="M10.18966,165.48253a5.59179,5.59179,0,0,0,3.29137-1.00437,3.45552,3.45552,0,0,0,1.43169-3.03089,3.55767,3.55767,0,0,0-1.54347-3.10542,14.74637,14.74637,0,0,0-4.38808-1.87815,30.01517,30.01517,0,0,1-4.12791-1.39412,10.93148,10.93148,0,0,1-2.43608-1.56226Q.11087,151.57442.11137,147.52a7.79454,7.79454,0,0,1,2.36154-5.68986,8.67992,8.67992,0,0,1,6.45253-2.38,15.77047,15.77047,0,0,1,4.10915.57638,15.14259,15.14259,0,0,0,2.659.57637q.52081,0,.92984-.74388h.40923l.26016,7.51194h-.59484a23.06433,23.06433,0,0,0-3.7375-5.18734A5.91675,5.91675,0,0,0,8.72075,140.231a5.37465,5.37465,0,0,0-3.55155,1.05977A3.50225,3.50225,0,0,0,3.905,144.11718a3.02633,3.02633,0,0,0,1.50589,2.715,13.62564,13.62564,0,0,0,3.70026,1.54315q2.194.5955,2.88219.81839.688.22257,1.71065.6133a8.81423,8.81423,0,0,1,1.67372.8184,11.83958,11.83958,0,0,1,1.376,1.07823,5.12635,5.12635,0,0,1,1.13428,1.4317,9.51178,9.51178,0,0,1-1.50622,10.69188,9.07547,9.07547,0,0,1-6.60091,2.43607,24.59139,24.59139,0,0,1-4.85331-.55791q-2.73315-.55791-3.0309-.55791-.52133,0-1.004,1.11582H.372L0,157.412H.63208a20.42233,20.42233,0,0,0,4.184,5.65294A7.7471,7.7471,0,0,0,10.18966,165.48253Z"/>
              <path class="cls-1" d="M42.35731,140.566V165.8914H37.15148l-12.756-24.06122v23.355h2.04533v.70628H21.34616v-.7063h2.04533V140.566H21.34616v-.707h10.2639l9.74354,18.66878V140.566H39.30794v-.70694h5.095v.70694Z"/>
              <path class="cls-1" d="M58.8327,166.26334q-6.21116,0-9.27866-3.36591-3.06747-3.36525-3.06849-9.74357,0-6.37734,3.292-10.041,3.29007-3.662,9.31559-3.66269,6.02421,0,9.09269,3.47735,3.06849,3.477,3.06785,9.87351,0,6.39615-3.10475,9.92955Q65.0429,166.264,58.8327,166.26334Zm4.76-11.86311v-2.75194q0-6.80567-.66936-8.59084a3.80112,3.80112,0,0,0-2.11952-2.529,5.35617,5.35617,0,0,0-1.89662-.29741,5.511,5.511,0,0,0-1.91508.29741,2.76914,2.76914,0,0,0-1.32025,1.0413A7.27053,7.27053,0,0,0,54.835,143.132a10.13488,10.13488,0,0,0-.50185,2.30548q-.22356,2.15744-.22356,6.35922v2.67743a40.37739,40.37739,0,0,0,.35347,6.45286,8.13761,8.13761,0,0,0,.87379,2.73282,3.92445,3.92445,0,0,0,3.57034,1.82276,3.62394,3.62394,0,0,0,3.81171-2.473Q63.59238,160.53654,63.59272,154.40023Z"/>
              <path class="cls-1" d="M86.50053,158.37944l3.087-10.71035-2.231-7.1031H85.23633v-.70694H96.76509v.70694h-2.194l5.54082,17.5899,4.46325-17.5899h-3.7563v-.70694h7.43743v.70694h-2.45452l-6.84262,25.69735H95.64927L90.14539,149.4536l-4.83454,16.80974H82.07554L72.70387,140.566H70.95563v-.70694H83.0423v.70694H80.43873Z"/>
              <path class="cls-1" d="M116.10322,139.85905h11.34212v.70694h-2.045v11.75166l.29741-.07452L136.18522,140.566h-2.93787v-.70694h6.5076v.70694h-2.231l-6.39617,7.14,8.92519,17.4791h2.00807v.70628h-11.9376v-.70628h1.74824l-6.137-11.86379-.33434.07452v11.78924h2.045v.70628H116.10326v-.70625h2.045V140.566h-2.045Z"/>
              <path class="cls-1" d="M143.39976,139.85905h11.34211v.70694h-2.045v24.6191h2.045v.70628H143.39974v-.70625h2.045V140.566h-2.045Z"/>
              <path class="cls-1" d="M177.61343,140.566V165.8914H172.4063l-12.75536-24.06122v23.355h2.04566v.70628h-5.095v-.7063h2.04566V140.566h-2.04566v-.707h10.2639l9.74358,18.66878V140.566h-2.045v-.70694h5.095v.70694Z"/>
              <path class="cls-1" d="M194.71993,165.51946q1.8221,0,2.32461-.66936a3.88428,3.88428,0,0,0,.50186-2.26855V155.367h-2.37935v-.8184h10.7097v.8184h-1.45016v10.48746h-.55725a2.20086,2.20086,0,0,0-1.89728-.92987,20.33583,20.33583,0,0,0-3.10542.66935,18.04266,18.04266,0,0,1-4.40654.66936q-6.06178,0-9.39077-3.27292-3.32766-3.27128-3.32766-9.83657,0-6.56233,3.4767-10.134a12.45514,12.45514,0,0,1,9.35318-3.56968,18.5724,18.5724,0,0,1,4.55558.57638,20.31561,20.31561,0,0,0,2.65962.57637.81331.81331,0,0,0,.55725-.16748,2.238,2.238,0,0,0,.40953-.57636h.40886l.29742,8.06985h-.66935a26.6005,26.6005,0,0,0-3.66333-5.67138,5.43175,5.43175,0,0,0-4.10914-2.06347q-5.68986,0-5.68986,11.11922v2.93793q0,5.57808,1.39411,8.42334Q192.117,165.51946,194.71993,165.51946Z"/>
            </g>
            <g>
              <path class="cls-1" d="M12.30288,179.99141a1.48355,1.48355,0,0,0-.57835-1.25761,7.569,7.569,0,0,0-2.08094-.92457,14.767,14.767,0,0,1-2.38-.95886A3.88763,3.88763,0,0,1,4.874,173.3717a3.38808,3.38808,0,0,1,.64067-2.02785,4.17723,4.17723,0,0,1,1.84056-1.39213,6.91917,6.91917,0,0,1,2.69292-.50119,6.30755,6.30755,0,0,1,2.67842.54406,4.2702,4.2702,0,0,1,1.82606,1.5372,4.021,4.021,0,0,1,.65023,2.25471H12.31245a1.89161,1.89161,0,0,0-.607-1.4983A2.49352,2.49352,0,0,0,10,171.75337a2.66543,2.66543,0,0,0-1.64767.44777,1.41413,1.41413,0,0,0-.58791,1.18044,1.354,1.354,0,0,0,.68914,1.14681,7.81015,7.81015,0,0,0,2.02818.8672,8.76525,8.76525,0,0,1,3.59375,1.8399,3.66671,3.66671,0,0,1,1.12736,2.73678,3.37726,3.37726,0,0,1-1.37759,2.8568,6.03343,6.03343,0,0,1-3.70982,1.03536,7.163,7.163,0,0,1-2.94813-.5922,4.66463,4.66463,0,0,1-2.02818-1.6236,4.15627,4.15627,0,0,1-.6987-2.3899H7.34079q0,2.32261,2.77469,2.32264a2.69784,2.69784,0,0,0,1.60909-.41942A1.36345,1.36345,0,0,0,12.30288,179.99141Z"/>
              <path class="cls-1" d="M26.36035,178.55575a6.25175,6.25175,0,0,1-1.09372,3.85853,3.5063,3.5063,0,0,1-2.953,1.45016,3.24881,3.24881,0,0,1-2.55344-1.098v4.91367H16.97591V173.24641H19.558l.09627,1.0215a3.273,3.273,0,0,1,2.64017-1.21407,3.52915,3.52915,0,0,1,2.99626,1.42641,6.41865,6.41865,0,0,1,1.06967,3.93107Zm-2.78458-.20245a4.10306,4.10306,0,0,0-.51538-2.24481,1.67893,1.67893,0,0,0-1.4983-.79,1.82349,1.82349,0,0,0-1.802,1.00173v4.26871a1.8548,1.8548,0,0,0,1.82112,1.03074Q23.576,181.61962,23.57577,178.3533Z"/>
              <path class="cls-1" d="M27.65158,178.36316a6.184,6.184,0,0,1,.59715-2.76579,4.37522,4.37522,0,0,1,1.71988-1.87881,5.01776,5.01776,0,0,1,2.60653-.66474,4.74786,4.74786,0,0,1,3.44472,1.29123,5.18187,5.18187,0,0,1,1.48841,3.507l.01912.71288a5.44822,5.44822,0,0,1-1.33937,3.8493,5.191,5.191,0,0,1-7.19245.00527,5.54387,5.54387,0,0,1-1.344-3.93106Zm2.78422.19719a3.89541,3.89541,0,0,0,.5589,2.27251,2.00769,2.00769,0,0,0,3.17995.00989,4.26682,4.26682,0,0,0,.56846-2.48486,3.86156,3.86156,0,0,0-.56846-2.258,1.8483,1.8483,0,0,0-1.59953-.80125,1.81787,1.81787,0,0,0-1.5804.79664,4.33506,4.33506,0,0,0-.55888,2.46508Z"/>
              <path class="cls-1" d="M45.10068,175.85788a7.55741,7.55741,0,0,0-1.00173-.07715,2.03982,2.03982,0,0,0-2.072,1.06966v6.8215H39.2427V173.24641h2.6306l.07716,1.24309a2.58008,2.58008,0,0,1,2.322-1.43567,2.93243,2.93243,0,0,1,.8672.1253Z"/>
              <path class="cls-1" d="M50.25571,170.68375v2.56268h1.78253v2.043H50.25571v5.20317a1.24168,1.24168,0,0,0,.22158.82829,1.11321,1.11321,0,0,0,.84807.2506,4.4462,4.4462,0,0,0,.8184-.06727v2.10961a5.71461,5.71461,0,0,1-1.68559.25061,2.65853,2.65853,0,0,1-2.98738-2.9577v-5.61733h-1.522v-2.043h1.522v-2.56266Z"/>
              <path class="cls-1" d="M59.23564,180.79068a.89845.89845,0,0,0-.50579-.80455,5.75844,5.75844,0,0,0-1.62364-.52494q-3.71938-.78048-3.71938-3.16015a2.86441,2.86441,0,0,1,1.15142-2.31736,4.64476,4.64476,0,0,1,3.01114-.92988,5.00257,5.00257,0,0,1,3.17466.93446,2.94608,2.94608,0,0,1,1.18967,2.42815H59.1295a1.34721,1.34721,0,0,0-.38512-.98721,1.63105,1.63105,0,0,0-1.20484-.39041,1.66874,1.66874,0,0,0-1.08878.31787,1.00547,1.00547,0,0,0-.38513.80916.85711.85711,0,0,0,.43855.74717,4.50407,4.50407,0,0,0,1.47852.4913,12.06143,12.06143,0,0,1,1.75417.4669,2.88193,2.88193,0,0,1,2.20591,2.804,2.71834,2.71834,0,0,1-1.22331,2.30747,5.27555,5.27555,0,0,1-3.16015.8817,5.5191,5.5191,0,0,1-2.32725-.4669,3.89988,3.89988,0,0,1-1.59459-1.282,2.97829,2.97829,0,0,1-.57835-1.75813h2.64044a1.4519,1.4519,0,0,0,.54933,1.13692,2.174,2.174,0,0,0,1.36773.395,2.0156,2.0156,0,0,0,1.20944-.30335A.94507.94507,0,0,0,59.23564,180.79068Z"/>
              <path class="cls-1" d="M68.2637,179.89513a3.21666,3.21666,0,0,1,.54406-1.80168,7.555,7.555,0,0,1,1.9995-1.84057,9.7999,9.7999,0,0,1-.98327-1.57546,3.49082,3.49082,0,0,1-.35611-1.53655,3.58371,3.58371,0,0,1,1.036-2.66424,3.78762,3.78762,0,0,1,2.78888-1.02613,3.63913,3.63913,0,0,1,2.57784.93447,3.05236,3.05236,0,0,1,1.00634,2.33187,3.67645,3.67645,0,0,1-1.69548,2.95769l-1.07886.78083,2.41821,2.81326a5.36549,5.36549,0,0,0,.56846-2.46639h2.37012a7.23905,7.23905,0,0,1-1.26222,4.43226l2.1004,2.43738H77.13743l-.72277-.829a6.332,6.332,0,0,1-3.56506,1.02152,4.88587,4.88587,0,0,1-3.3336-1.0934A3.63268,3.63268,0,0,1,68.2637,179.89513Zm4.72111,1.72449a3.53734,3.53734,0,0,0,1.89793-.55725l-2.76513-3.19114-.20245.14442a2.19854,2.19854,0,0,0-.85731,1.72054,1.819,1.819,0,0,0,.53483,1.35982A1.9051,1.9051,0,0,0,72.98481,181.61962ZM71.9633,173.102a3.29044,3.29044,0,0,0,.84807,1.82143l.74189-.4913a2.53615,2.53615,0,0,0,.78543-.71354,1.53031,1.53031,0,0,0,.2163-.82828,1.14721,1.14721,0,0,0-.35611-.83818,1.36882,1.36882,0,0,0-1.8742.029A1.43161,1.43161,0,0,0,71.9633,173.102Z"/>
              <path class="cls-1" d="M95.13555,177.59227H89.58616v3.75763H96.099v2.322h-9.4033V169.64313H96.0799v2.34109H89.58616v3.34349h5.54939Z"/>
              <path class="cls-1" d="M101.26395,180.27036l1.93619-7.024h2.91022l-3.51693,10.42546h-2.659l-3.51692-10.42546h2.90956Z"/>
              <path class="cls-1" d="M112.00729,183.86444a5.11763,5.11763,0,0,1-3.73389-1.40663,5.00073,5.00073,0,0,1-1.44027-3.74773v-.27038a6.30066,6.30066,0,0,1,.60671-2.808,4.45527,4.45527,0,0,1,1.71988-1.90782,4.82675,4.82675,0,0,1,2.53894-.67,4.32589,4.32589,0,0,1,3.36722,1.34927,5.47983,5.47983,0,0,1,1.22858,3.82489v1.13692H109.6563a2.58313,2.58313,0,0,0,.81377,1.63811,2.46263,2.46263,0,0,0,1.71989.6166,3.0037,3.0037,0,0,0,2.51453-1.16593l1.36839,1.53193a4.18242,4.18242,0,0,1-1.69548,1.38289A5.57055,5.57055,0,0,1,112.00729,183.86444Zm-.31853-8.56575a1.73947,1.73947,0,0,0-1.344.56184,2.90949,2.90949,0,0,0-.66013,1.60646h3.8737v-.2229a2.075,2.075,0,0,0-.5012-1.437A1.79506,1.79506,0,0,0,111.68876,175.29869Z"/>
              <path class="cls-1" d="M120.43788,173.24641l.08639,1.20484a3.64127,3.64127,0,0,1,2.9966-1.39741,3.02656,3.02656,0,0,1,2.46706.97337,4.54249,4.54249,0,0,1,.82829,2.90955v6.73511H124.032v-6.66715a1.805,1.805,0,0,0-.38579-1.28662,1.72482,1.72482,0,0,0-1.28134-.39964,1.91573,1.91573,0,0,0-1.7634,1.00173v7.35171h-2.78426V173.24641Z"/>
              <path class="cls-1" d="M132.26007,170.68375v2.56268h1.78253v2.043h-1.78253v5.20317a1.24168,1.24168,0,0,0,.22158.82829,1.11322,1.11322,0,0,0,.84807.2506,4.44613,4.44613,0,0,0,.8184-.06727v2.10961a5.71461,5.71461,0,0,1-1.68559.25061,2.65855,2.65855,0,0,1-2.98738-2.9577v-5.61733h-1.522v-2.043h1.522v-2.56266Z"/>
              <path class="cls-1" d="M141.24,180.79068a.89845.89845,0,0,0-.50579-.80455,5.75848,5.75848,0,0,0-1.6236-.52493q-3.71938-.78048-3.71938-3.16015a2.86446,2.86446,0,0,1,1.15142-2.31736,4.64467,4.64467,0,0,1,3.01114-.92988,5.00256,5.00256,0,0,1,3.17466.93446,2.94608,2.94608,0,0,1,1.18967,2.42815h-2.78425a1.34722,1.34722,0,0,0-.38513-.98721,1.63105,1.63105,0,0,0-1.20484-.39041,1.66874,1.66874,0,0,0-1.08878.31787,1.00547,1.00547,0,0,0-.38513.80916.85711.85711,0,0,0,.43855.74717,4.50407,4.50407,0,0,0,1.47852.4913,12.06109,12.06109,0,0,1,1.75417.4669,2.88188,2.88188,0,0,1,2.20591,2.804,2.71834,2.71834,0,0,1-1.22331,2.30747,5.27563,5.27563,0,0,1-3.16015.88171,5.51929,5.51929,0,0,1-2.32725-.46691,3.8997,3.8997,0,0,1-1.59458-1.282,2.97816,2.97816,0,0,1-.57835-1.75813H137.704a1.452,1.452,0,0,0,.54934,1.13692,2.17385,2.17385,0,0,0,1.36772.395,2.01558,2.01558,0,0,0,1.20944-.30335A.94507.94507,0,0,0,141.24,180.79068Z"/>
              <path class="cls-1" d="M161.84894,178.99891a4.96145,4.96145,0,0,1-1.67175,3.56507,5.8694,5.8694,0,0,1-3.97393,1.30046,5.27834,5.27834,0,0,1-4.24431-1.81616,7.44428,7.44428,0,0,1-1.54642-4.98621v-.85731a8.4076,8.4076,0,0,1,.71288-3.56506,5.34758,5.34758,0,0,1,2.03774-2.3655,6.36941,6.36941,0,0,1,6.99032.47679,5.33623,5.33623,0,0,1,1.71525,3.65212h-2.89109a2.93088,2.93088,0,0,0-.75575-1.9705,2.80109,2.80109,0,0,0-1.98037-.612,2.4565,2.4565,0,0,0-2.163,1.036,5.80427,5.80427,0,0,0-.73728,3.21291v1.05977a6.20844,6.20844,0,0,0,.68914,3.32436,2.41029,2.41029,0,0,0,2.17293,1.05052,2.85166,2.85166,0,0,0,1.99886-.612,2.76418,2.76418,0,0,0,.7564-1.89332Z"/>
              <path class="cls-1" d="M168.36312,183.86444a5.11761,5.11761,0,0,1-3.73388-1.40663,5.00069,5.00069,0,0,1-1.44027-3.74773v-.27038a6.30065,6.30065,0,0,1,.6067-2.808,4.45529,4.45529,0,0,1,1.71989-1.90782,4.82674,4.82674,0,0,1,2.53893-.67,4.3259,4.3259,0,0,1,3.36723,1.34927,5.47983,5.47983,0,0,1,1.22858,3.82489v1.13692h-6.63817a2.58314,2.58314,0,0,0,.81378,1.63811,2.46261,2.46261,0,0,0,1.71988.6166,3.00372,3.00372,0,0,0,2.51454-1.16593l1.36839,1.53193a4.18248,4.18248,0,0,1-1.69548,1.38289A5.57063,5.57063,0,0,1,168.36312,183.86444Zm-.31852-8.56575a1.73945,1.73945,0,0,0-1.344.56184,2.90947,2.90947,0,0,0-.66012,1.60646h3.87369v-.2229a2.075,2.075,0,0,0-.50119-1.437A1.79507,1.79507,0,0,0,168.0446,175.29869Z"/>
              <path class="cls-1" d="M176.79306,173.24641l.087,1.20484a3.64044,3.64044,0,0,1,2.99661-1.39741,3.02427,3.02427,0,0,1,2.4664.97337,4.54249,4.54249,0,0,1,.82894,2.90955v6.73511h-2.78489v-6.66715a1.80817,1.80817,0,0,0-.38513-1.28662,1.72692,1.72692,0,0,0-1.28134-.39964,1.91509,1.91509,0,0,0-1.76341,1.00173v7.35171h-2.78489V173.24641Z"/>
              <path class="cls-1" d="M188.61593,170.68375v2.56268h1.78253v2.043h-1.78253v5.20317a1.24168,1.24168,0,0,0,.22158.82829,1.11321,1.11321,0,0,0,.84807.2506,4.4462,4.4462,0,0,0,.8184-.06727v2.10961a5.71461,5.71461,0,0,1-1.68559.25061,2.65853,2.65853,0,0,1-2.98738-2.9577v-5.61733h-1.522v-2.043h1.522v-2.56266Z"/>
              <path class="cls-1" d="M196.74778,183.86444a5.11757,5.11757,0,0,1-3.73388-1.40663,5.00062,5.00062,0,0,1-1.44027-3.74773v-.27038a6.30079,6.30079,0,0,1,.6067-2.808,4.45536,4.45536,0,0,1,1.71989-1.90782,4.82675,4.82675,0,0,1,2.53894-.67,4.32592,4.32592,0,0,1,3.36722,1.34927A5.47974,5.47974,0,0,1,201.035,178.228v1.13692h-6.63817a2.58319,2.58319,0,0,0,.81378,1.63811,2.46259,2.46259,0,0,0,1.71989.6166,3.0037,3.0037,0,0,0,2.51453-1.16593l1.36839,1.53193a4.18242,4.18242,0,0,1-1.69548,1.38289A5.57066,5.57066,0,0,1,196.74778,183.86444Zm-.31852-8.56575a1.7395,1.7395,0,0,0-1.344.56184,2.90947,2.90947,0,0,0-.66012,1.60646h3.87369v-.2229a2.07478,2.07478,0,0,0-.50119-1.437A1.79513,1.79513,0,0,0,196.42926,175.29869Z"/>
              <path class="cls-1" d="M208.47372,175.85788a7.55886,7.55886,0,0,0-1.00239-.07715A2.0396,2.0396,0,0,0,205.4,176.85039v6.8215h-2.78422V173.24641h2.62994l.07716,1.24309a2.58009,2.58009,0,0,1,2.322-1.43567,2.9294,2.9294,0,0,1,.8672.1253Z"/>
            </g>
            <path class="cls-2" d="M208.47791,63.39533l.0211-5.676v-.483c.114-52.078-13.449-56.849-56.479-56.894l-53.781-.1L56.63.00037c-43.029-.049-56.6,4.692-56.6,56.77v.483l.00769,5.672H0v8.562c.076,52.079,13.657,56.8,56.687,56.687l63.919.276,31.077.068c43.029.207,56.621-4.485,56.811-56.563v-.483l.018-8.077ZM56.663,19.84937l41.578.243v-.001l53.706.1c33.938-.553,37.079,2.773,36.7,36.973v.474l-.029,8.085.03771.00012-.01269,5.71393v.474c.021,4.01245-.00739,7.5846-.12262,10.79L146.842,40.78742l-.00007-.00006L79.26086,108.42078l-10.2099-.04455c5.70929-5.698,11.80494-11.77918,17.95307-17.91089q19.161-19.117,38.305-38.247l-24.984-24.98-75.3836,75.17859A16.31047,16.31047,0,0,1,21.64,95.97962L74.848,42.20539l-5.23205-5.221L20.16248,86.436c-.29279-4.17651-.35492-9.10242-.31147-14.95068v-6.1839l.042-.00006-.012-8.085v-.475C19.581,22.54139,22.725,19.22242,56.663,19.84937Zm122.45928,86.18756L143.608,70.5254l-15.11,15.02.001.001-23.09625,22.9884-9.83929-.0429L143.363,60.68439c11.97857,11.7912,28.72058,28.27722,41.21557,40.73993a12.91148,12.91148,0,0,1-5.45627,4.61261Z"/>
          </g>
        </g>
      </g>
    </g>
  </g>
</Logo>
 
  )
}

export default SnowKingLogo
