import React from 'react';
import Video from './Icons/video-duotone.svg'
import { useStaticQuery, graphql } from 'gatsby';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Flex,
    Box,
    Button,
    useDisclosure,
    Link
} from '@chakra-ui/react';
import { RichText } from 'prismic-reactjs'
import { linkResolver } from '../../utils/linkResolver';

export default function WatchLiveModal(props) {

    const data = useStaticQuery(graphql`
    query {
      allPrismicModal(filter: {uid: {eq: "watch-live"}}) {
        edges {
          node {
            data {
              modal_title
              modal_content {
                richText
              }
            }
          }
        }
      }
    }
  `)

  const pageContent = data.allPrismicModal.edges[0].node.data;


    const { isOpen, onOpen, onClose } = useDisclosure()
    return (
        <>
        {props.button ? (
                 <Flex flexDirection="column" p="2" h="100%">
                 <Box mb="4" mt="4"><img src={Video} alt="buy tickets icon" width="55%" style={{ marginLeft: 'auto', marginRight: 'auto'}}/></Box>
                 <Box mt="auto">
                     <Button onClick={onOpen} variant="black-button" isFullWidth size="lg" textTransform="uppercase">{pageContent.modal_title}</Button></Box>
             </Flex>
            ) : (
                <Link onClick={onOpen} variant="top-nav-item">
                    {pageContent.modal_title}
                </Link>
            ) }
           

            <Modal isOpen={isOpen} onClose={onClose} autoFocus={false} returnFocusOnClose={false}>
                <ModalOverlay />
                <ModalContent>
                <ModalHeader>
                    {pageContent.modal_title}
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <RichText render={pageContent.modal_content.richText} linkResolver={linkResolver} />
                </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}