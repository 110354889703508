// Provide Chakra as high in the React component tree as possible
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react'

import "@fontsource/lato";
import "@fontsource/abril-fatface";
import theme from './theme'
import React from 'react'
import Layout from './components/layout'

export const wrapPageElement = ({ element }) => {
    return (
        <ChakraProvider resetCSS theme={theme}>
            <ColorModeScript
                initialColorMode={theme.config.initialColorMode}
                key="chakra-ui-no-flash"
                />
            <Layout>
                {element}
            </Layout>
        </ChakraProvider>
    )
}