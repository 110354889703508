import React from "react";
import styled from "@emotion/styled";

const Logo = styled.svg`
  width: ${props => props.width ? props.width : '30px' };
  height: auto;
      .icon {
        fill: #cc2027;
      }
      .icon:hover {
          fill: black;
      }
  `

const FacebookIcon = (props) => {
  return (
<a href='https://www.facebook.com/jacksonholemoose' target="_blank" rel="noopener noreferrer">
<Logo xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 448" {...props}>
  <g id="Layer_2" data-name="Layer 2">
    <g id="Layer_1-2" data-name="Layer 1">
      <path className="icon" d="M400,0H48A48,48,0,0,0,0,48V400a48,48,0,0,0,48,48H185.25V295.69h-63V224h63V169.36c0-62.15,37-96.48,93.67-96.48,27.14,0,55.52,4.84,55.52,4.84v61H303.17c-30.81,0-40.42,19.12-40.42,38.73V224h68.78l-11,71.69H262.75V448H400a48,48,0,0,0,48-48V48A48,48,0,0,0,400,0Z"/>
    </g>
  </g>
</Logo>
</a>
  );
};

export default FacebookIcon;
