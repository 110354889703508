import React from 'react'
import { Box } from '@chakra-ui/react'

const StripeDivder = () => (
    <Box>
        <Box 
            bgColor="brand.600"
            height="12px"
            mb="5px"
        />
        <Box 
            bgColor="shadow.900"
            height="6px"
            mb="0"
        />
    </Box>
)

export default StripeDivder