import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'

const Logo = styled.svg`
width: ${props => props.width ? props.width : '275px' };
height: 100%;
margin: ${props => props.margin ? props.margin: 'unset'};

.cls-1 {
        isolation: isolate;
      }

      .cls-2, .cls-3 {
        fill: #fff;
      }

      .cls-3 {
        fill-rule: evenodd;
      }
`

const SnowKingLogo = (props) => {
  return (
    <Link to="/">
    <Logo xmlns="http://www.w3.org/2000/svg" viewBox="0 0 531.45703 128.52458" {...props}>
  <g id="Layer_2" data-name="Layer 2">
    <g id="Layer_1-2" data-name="Layer 1">
      <g>
        <g class="cls-1">
          <g class="cls-1">
            <path class="cls-2" d="M237.773,77.64982a8.28057,8.28057,0,0,0,4.874-1.48731,5.1171,5.1171,0,0,0,2.12012-4.48828,5.26831,5.26831,0,0,0-2.28564-4.59863,21.83743,21.83743,0,0,0-6.49805-2.78125,44.44649,44.44649,0,0,1-6.11279-2.06446,16.18788,16.18788,0,0,1-3.60743-2.31347q-3.41527-2.86232-3.41455-8.86621a11.54249,11.54249,0,0,1,3.49707-8.42579Q229.84229,39.1,235.90088,39.1a23.354,23.354,0,0,1,6.085.85352,22.42294,22.42294,0,0,0,3.9375.85351q.77124,0,1.37695-1.10156h.606l.38525,11.124h-.88086a34.15471,34.15471,0,0,0-5.53466-7.68164,8.7617,8.7617,0,0,0-6.27832-2.8916,7.959,7.959,0,0,0-5.25928,1.56934,5.18627,5.18627,0,0,0-1.87207,4.18554,4.48153,4.48153,0,0,0,2.23,4.02051,20.17779,20.17779,0,0,0,5.47949,2.28516q3.249.88184,4.26807,1.21191,1.0188.32959,2.5332.9082a13.05155,13.05155,0,0,1,2.47852,1.21192,17.53261,17.53261,0,0,1,2.03759,1.59668,7.59163,7.59163,0,0,1,1.67969,2.12012,12.60783,12.60783,0,0,1,1.48682,6.27832,12.70637,12.70637,0,0,1-3.71729,9.55468,13.43932,13.43932,0,0,1-9.7749,3.60743,36.4153,36.4153,0,0,1-7.187-.82618q-4.04736-.82617-4.48828-.82617-.772,0-1.48682,1.65235h-.771l-.55079-13.10743h.936a30.24292,30.24292,0,0,0,6.1958,8.3711A11.47227,11.47227,0,0,0,237.773,77.64982Z"/>
            <path class="cls-2" d="M285.4082,40.75235V78.25528h-7.709L258.80957,42.62442v34.585h3.02881v1.04589H254.294V77.20939h3.0288v-36.457H254.294V39.70548h15.19921L283.92188,67.351V40.75235h-3.0293V39.70548h7.54492v1.04687Z"/>
            <path class="cls-2" d="M309.80566,78.80607q-9.19774,0-13.74023-4.98438Q291.523,68.83829,291.52148,59.393q0-9.44385,4.875-14.86914Q301.26856,39.101,310.19141,39.1q8.92089,0,13.46484,5.14941,4.54395,5.14893,4.543,14.6211,0,9.47168-4.59766,14.7041Q319.002,78.807,309.80566,78.80607Zm7.04883-17.56739V57.16349q0-10.07812-.99121-12.72168-1.10155-3.02784-3.13867-3.74512a7.93164,7.93164,0,0,0-2.80859-.44043,8.161,8.161,0,0,0-2.83594.44043,4.10067,4.10067,0,0,0-1.95508,1.542,10.76661,10.76661,0,0,0-1.23926,2.31348,15.0074,15.0074,0,0,0-.74316,3.41406q-.33105,3.19482-.33106,9.417v3.96485a59.79076,59.79076,0,0,0,.52344,9.55566,12.05054,12.05054,0,0,0,1.29395,4.04688,5.81145,5.81145,0,0,0,5.28711,2.69922q4.34911,0,5.64453-3.66211Q316.854,70.32559,316.85449,61.23868Z"/>
            <path class="cls-2" d="M350.77734,67.13126l4.57129-15.86035-3.30371-10.51856h-3.13965V39.70548h17.07227v1.04687h-3.249l8.20507,26.04786L377.543,40.75235h-5.5625V39.70548h11.01367v1.04687h-3.63476L369.22656,78.80607H364.3252l-8.1504-24.89258-7.15918,24.89258h-4.791L330.34668,40.75235h-2.58887V39.70548h17.89844v1.04687h-3.85547Z"/>
            <path class="cls-2" d="M394.61426,39.70548h16.7959v1.04687h-3.02832V58.1547l.44043-.11035,15.53027-17.292H420.002V39.70548h9.63672v1.04687H426.335L416.86328,51.3256l13.2168,25.88379h2.97363v1.04589H415.376V77.20939h2.58886L408.877,59.641l-.49511.11035v17.458h3.02832v1.04589h-16.7959V77.20939h3.02832v-36.457h-3.02832Z"/>
            <path class="cls-2" d="M435.03613,39.70548H451.832v1.04687h-3.02832v36.457H451.832v1.04589h-16.7959V77.20939h3.02832v-36.457h-3.02832Z"/>
            <path class="cls-2" d="M485.70117,40.75235V78.25528h-7.71094L459.10156,42.62442v34.585h3.0293v1.04589h-7.54492V77.20939h3.02929v-36.457h-3.02929V39.70548h15.19922L484.21387,67.351V40.75235h-3.02832V39.70548h7.54492v1.04687Z"/>
            <path class="cls-2" d="M511.0332,77.7045q2.69824,0,3.44239-.99121a5.75235,5.75235,0,0,0,.74316-3.35937V62.67032h-3.52344V61.45841h15.85938v1.21191h-2.14746V78.2006h-.8252a3.25913,3.25913,0,0,0-2.80957-1.377,30.11447,30.11447,0,0,0-4.59863.99121,26.719,26.719,0,0,1-6.52539.99122q-8.97657,0-13.90625-4.84668-4.92774-4.84425-4.92774-14.56641,0-9.71778,5.14844-15.00684,5.14893-5.28661,13.85059-5.28613a27.50286,27.50286,0,0,1,6.74609.85352,30.0844,30.0844,0,0,0,3.93848.85351,1.20443,1.20443,0,0,0,.82519-.248,3.31315,3.31315,0,0,0,.60645-.85351h.60547l.44043,11.95019h-.99121a39.3921,39.3921,0,0,0-5.42481-8.39843,8.04362,8.04362,0,0,0-6.085-3.05567q-8.42579,0-8.42578,16.46582V61.018q0,8.26026,2.06445,12.47363Q507.17871,77.70451,511.0332,77.7045Z"/>
          </g>
        </g>
        <g class="cls-1">
          <g class="cls-1">
            <path class="cls-2" d="M240.90234,99.13517a2.1969,2.1969,0,0,0-.85644-1.86231,11.20817,11.20817,0,0,0-3.08154-1.36914,21.86966,21.86966,0,0,1-3.52442-1.41992q-3.53906-1.91162-3.53857-5.15137a5.01731,5.01731,0,0,1,.94873-3.00293,6.18593,6.18593,0,0,1,2.72558-2.06152,10.24623,10.24623,0,0,1,3.9878-.74219,9.34073,9.34073,0,0,1,3.96631.80567,6.3233,6.3233,0,0,1,2.7041,2.27636,5.95447,5.95447,0,0,1,.96289,3.33887H240.9165a2.80117,2.80117,0,0,0-.89892-2.21875,3.6925,3.6925,0,0,0-2.52539-.792,3.94713,3.94713,0,0,0-2.43994.66308,2.09411,2.09411,0,0,0-.87061,1.74805,2.00508,2.00508,0,0,0,1.02051,1.69824,11.56552,11.56552,0,0,0,3.00342,1.28418,12.98,12.98,0,0,1,5.32177,2.72461,5.42979,5.42979,0,0,1,1.66944,4.05274,5.00114,5.00114,0,0,1-2.04,4.23047,8.93456,8.93456,0,0,1-5.49365,1.5332,10.60716,10.60716,0,0,1-4.36573-.87695,6.90748,6.90748,0,0,1-3.00341-2.4043,6.15466,6.15466,0,0,1-1.03467-3.53906h4.29492q0,3.43944,4.10889,3.43945a3.995,3.995,0,0,0,2.38281-.62109A2.01911,2.01911,0,0,0,240.90234,99.13517Z"/>
            <path class="cls-2" d="M261.71924,97.00919a9.25791,9.25791,0,0,1-1.61963,5.71387,5.19231,5.19231,0,0,1-4.373,2.14746,4.811,4.811,0,0,1-3.78125-1.626v7.27637h-4.123v-21.374H251.646l.14257,1.51269a4.8468,4.8468,0,0,1,3.90967-1.79785,5.22615,5.22615,0,0,1,4.437,2.1123,9.50512,9.50512,0,0,1,1.584,5.82129Zm-4.12354-.2998a6.07609,6.07609,0,0,0-.76318-3.32422,2.48623,2.48623,0,0,0-2.21875-1.16993,2.70029,2.70029,0,0,0-2.66846,1.4834v6.32129a2.74668,2.74668,0,0,0,2.69678,1.52637Q257.596,101.5463,257.5957,96.70939Z"/>
            <path class="cls-2" d="M263.63135,96.724a9.15741,9.15741,0,0,1,.88427-4.0957,6.47908,6.47908,0,0,1,2.54688-2.78223,7.43058,7.43058,0,0,1,3.85986-.98437,7.03079,7.03079,0,0,1,5.10108,1.91211,7.67349,7.67349,0,0,1,2.2041,5.19336l.02832,1.05566a8.068,8.068,0,0,1-1.9834,5.7002,7.687,7.687,0,0,1-10.65088.00781,8.20953,8.20953,0,0,1-1.99023-5.82129Zm4.123.292a5.76854,5.76854,0,0,0,.82764,3.36523,2.97309,2.97309,0,0,0,4.709.01465,6.31849,6.31849,0,0,0,.84179-3.67969,5.71838,5.71838,0,0,0-.84179-3.34375,2.737,2.737,0,0,0-2.36866-1.18652,2.692,2.692,0,0,0-2.34033,1.17969A6.41967,6.41967,0,0,0,267.75439,97.016Z"/>
            <path class="cls-2" d="M289.4707,93.01407a11.19238,11.19238,0,0,0-1.4834-.11425q-2.34081,0-3.06835,1.584v10.10156h-4.123V89.14689h3.89551l.11425,1.84082a3.82069,3.82069,0,0,1,3.43848-2.126,4.3428,4.3428,0,0,1,1.28418.18555Z"/>
            <path class="cls-2" d="M297.10449,85.352v3.79493h2.63965v3.02539h-2.63965v7.70507a1.83872,1.83872,0,0,0,.32813,1.22657,1.64852,1.64852,0,0,0,1.25586.37109,6.58376,6.58376,0,0,0,1.21191-.09961v3.124a8.46249,8.46249,0,0,1-2.49609.3711q-4.33887,0-4.42383-4.37988V92.17228h-2.25391V89.14689h2.25391V85.352Z"/>
            <path class="cls-2" d="M310.40234,100.31876a1.33046,1.33046,0,0,0-.749-1.19141A8.52731,8.52731,0,0,0,307.249,98.35q-5.5078-1.15577-5.50781-4.67969a4.24179,4.24179,0,0,1,1.70508-3.43164,6.87806,6.87806,0,0,1,4.459-1.377,7.408,7.408,0,0,1,4.70118,1.38379,4.36265,4.36265,0,0,1,1.76171,3.5957h-4.123a1.995,1.995,0,0,0-.57032-1.46191,2.41537,2.41537,0,0,0-1.78418-.57813,2.47111,2.47111,0,0,0-1.6123.47071,1.48892,1.48892,0,0,0-.57031,1.19824,1.26925,1.26925,0,0,0,.64941,1.10644,6.66986,6.66986,0,0,0,2.18946.72754,17.86106,17.86106,0,0,1,2.59765.69141q3.26661,1.1997,3.2666,4.15234a4.02537,4.02537,0,0,1-1.81152,3.417,7.81239,7.81239,0,0,1-4.67969,1.30567,8.17318,8.17318,0,0,1-3.44629-.69141,5.775,5.775,0,0,1-2.36133-1.89844,4.4102,4.4102,0,0,1-.85644-2.60351H305.166a2.15007,2.15007,0,0,0,.81347,1.68359,3.21917,3.21917,0,0,0,2.02539.585,2.98474,2.98474,0,0,0,1.791-.44922A1.39945,1.39945,0,0,0,310.40234,100.31876Z"/>
            <path class="cls-2" d="M323.77148,98.99259a4.76336,4.76336,0,0,1,.80567-2.668,11.18768,11.18768,0,0,1,2.96094-2.72559,14.51366,14.51366,0,0,1-1.45606-2.333,5.16942,5.16942,0,0,1-.52734-2.27539,5.3069,5.3069,0,0,1,1.53418-3.94532,5.60883,5.60883,0,0,1,4.12988-1.51953,5.389,5.389,0,0,1,3.81738,1.38379,4.52019,4.52019,0,0,1,1.49024,3.45313,5.44424,5.44424,0,0,1-2.51075,4.37988L332.418,93.89884l3.581,4.166a7.9457,7.9457,0,0,0,.8418-3.65234h3.50977a10.71989,10.71989,0,0,1-1.86914,6.56348l3.11035,3.60937h-4.67969l-1.07031-1.22754a9.37659,9.37659,0,0,1-5.2793,1.5127,7.23522,7.23522,0,0,1-4.93652-1.61914A5.37942,5.37942,0,0,1,323.77148,98.99259Zm6.99122,2.55371a5.23838,5.23838,0,0,0,2.81054-.8252l-4.09472-4.72558-.29981.21387a3.25564,3.25564,0,0,0-1.26953,2.54785,2.69373,2.69373,0,0,0,.792,2.01367A2.82118,2.82118,0,0,0,330.7627,101.5463ZM329.25,88.933a4.87275,4.87275,0,0,0,1.25586,2.69726l1.09863-.72754a3.75548,3.75548,0,0,0,1.16309-1.05664,2.26605,2.26605,0,0,0,.32031-1.22656,1.69888,1.69888,0,0,0-.52734-1.24121,2.027,2.027,0,0,0-2.77539.043A2.12,2.12,0,0,0,329.25,88.933Z"/>
            <path class="cls-2" d="M363.56445,95.58243h-8.21777v5.56446h9.64453v3.43847h-13.9248V83.811h13.89648v3.46679h-9.61621v4.95118h8.21777Z"/>
            <path class="cls-2" d="M372.63965,99.54825l2.86719-10.40136h4.30957l-5.208,15.43847h-3.9375l-5.208-15.43847h4.30859Z"/>
            <path class="cls-2" d="M388.54883,104.87052a7.57843,7.57843,0,0,1-5.5293-2.083,7.40521,7.40521,0,0,1-2.13281-5.5498v-.40039a9.33025,9.33025,0,0,1,.89844-4.15821,6.59774,6.59774,0,0,1,2.54687-2.82519,7.1476,7.1476,0,0,1,3.75977-.99219,6.406,6.406,0,0,1,4.98632,1.99805,8.1147,8.1147,0,0,1,1.81934,5.66406v1.68359h-9.83008a3.82517,3.82517,0,0,0,1.20508,2.42578,3.64675,3.64675,0,0,0,2.54688.91309,4.448,4.448,0,0,0,3.72363-1.72656l2.02637,2.26855a6.19367,6.19367,0,0,1-2.51075,2.04785A8.24932,8.24932,0,0,1,388.54883,104.87052ZM388.07715,92.186a2.5759,2.5759,0,0,0-1.99024.832,4.3083,4.3083,0,0,0-.97753,2.37891h5.73632v-.33008a3.0727,3.0727,0,0,0-.74218-2.12793A2.6582,2.6582,0,0,0,388.07715,92.186Z"/>
            <path class="cls-2" d="M401.0332,89.14689l.12793,1.78418a5.39218,5.39218,0,0,1,4.4375-2.06934,4.48187,4.48187,0,0,1,3.65332,1.44141,6.72676,6.72676,0,0,1,1.22657,4.30859v9.97363h-4.123v-9.873a2.6729,2.6729,0,0,0-.57129-1.90528,2.55416,2.55416,0,0,0-1.89746-.5918,2.8369,2.8369,0,0,0-2.61133,1.4834v10.88672h-4.12305V89.14689Z"/>
            <path class="cls-2" d="M418.54,85.352v3.79493h2.63965v3.02539H418.54v7.70507a1.83872,1.83872,0,0,0,.32812,1.22657,1.64852,1.64852,0,0,0,1.25586.37109,6.5838,6.5838,0,0,0,1.21192-.09961v3.124a8.4626,8.4626,0,0,1-2.4961.3711q-4.33885,0-4.42382-4.37988V92.17228h-2.25391V89.14689H414.416V85.352Z"/>
            <path class="cls-2" d="M431.83789,100.31876a1.33046,1.33046,0,0,0-.749-1.19141,8.52731,8.52731,0,0,0-2.4043-.77734q-5.50781-1.15577-5.50781-4.67969a4.24179,4.24179,0,0,1,1.70508-3.43164,6.878,6.878,0,0,1,4.459-1.377,7.408,7.408,0,0,1,4.70117,1.38379,4.36263,4.36263,0,0,1,1.76172,3.5957h-4.12305a1.995,1.995,0,0,0-.57031-1.46191,2.41539,2.41539,0,0,0-1.78418-.57813,2.47111,2.47111,0,0,0-1.6123.47071,1.4889,1.4889,0,0,0-.57032,1.19824,1.26926,1.26926,0,0,0,.64942,1.10644,6.66988,6.66988,0,0,0,2.18945.72754,17.86087,17.86087,0,0,1,2.59766.69141q3.26659,1.1997,3.2666,4.15234a4.02539,4.02539,0,0,1-1.81152,3.417,7.81239,7.81239,0,0,1-4.67969,1.30567,8.17315,8.17315,0,0,1-3.44629-.69141,5.77485,5.77485,0,0,1-2.36133-1.89844,4.4102,4.4102,0,0,1-.85644-2.60351h3.91015a2.15011,2.15011,0,0,0,.81348,1.68359,3.21917,3.21917,0,0,0,2.02539.585,2.98474,2.98474,0,0,0,1.791-.44922A1.39947,1.39947,0,0,0,431.83789,100.31876Z"/>
            <path class="cls-2" d="M462.35645,97.66544a7.34713,7.34713,0,0,1-2.47559,5.2793,8.69166,8.69166,0,0,1-5.88477,1.92578,7.81641,7.81641,0,0,1-6.28515-2.68945,11.02379,11.02379,0,0,1-2.29-7.38379V93.52774a12.45043,12.45043,0,0,1,1.05566-5.27929,7.91889,7.91889,0,0,1,3.01758-3.50293,9.43209,9.43209,0,0,1,10.35156.70605,7.90206,7.90206,0,0,1,2.54,5.40821h-4.28125a4.34014,4.34014,0,0,0-1.11914-2.918,4.148,4.148,0,0,0-2.93262-.90625,3.63766,3.63766,0,0,0-3.20312,1.53418,8.59544,8.59544,0,0,0-1.0918,4.75781v1.56934a9.19388,9.19388,0,0,0,1.02051,4.92285,3.56928,3.56928,0,0,0,3.21777,1.55566,4.22287,4.22287,0,0,0,2.96-.90625,4.09335,4.09335,0,0,0,1.12012-2.80371Z"/>
            <path class="cls-2" d="M472.00293,104.87052a7.57843,7.57843,0,0,1-5.5293-2.083,7.40521,7.40521,0,0,1-2.13281-5.5498v-.40039a9.33025,9.33025,0,0,1,.89844-4.15821,6.59774,6.59774,0,0,1,2.54687-2.82519,7.14765,7.14765,0,0,1,3.75977-.99219,6.406,6.406,0,0,1,4.98633,1.99805,8.11474,8.11474,0,0,1,1.81933,5.66406v1.68359h-9.83008a3.82517,3.82517,0,0,0,1.20508,2.42578,3.64675,3.64675,0,0,0,2.54688.91309,4.448,4.448,0,0,0,3.72363-1.72656l2.02637,2.26855a6.19371,6.19371,0,0,1-2.51074,2.04785A8.2494,8.2494,0,0,1,472.00293,104.87052ZM471.53125,92.186a2.57587,2.57587,0,0,0-1.99023.832,4.30831,4.30831,0,0,0-.97754,2.37891h5.73632v-.33008a3.0727,3.0727,0,0,0-.74218-2.12793A2.6582,2.6582,0,0,0,471.53125,92.186Z"/>
            <path class="cls-2" d="M484.48633,89.14689l.1289,1.78418a5.39091,5.39091,0,0,1,4.4375-2.06934,4.47849,4.47849,0,0,1,3.65235,1.44141,6.72662,6.72662,0,0,1,1.22754,4.30859v9.97363h-4.124v-9.873a2.67749,2.67749,0,0,0-.57031-1.90528,2.55719,2.55719,0,0,0-1.89746-.5918,2.8359,2.8359,0,0,0-2.61133,1.4834v10.88672h-4.124V89.14689Z"/>
            <path class="cls-2" d="M501.99414,85.352v3.79493h2.63965v3.02539h-2.63965v7.70507a1.83872,1.83872,0,0,0,.32813,1.22657,1.64849,1.64849,0,0,0,1.25585.37109,6.5838,6.5838,0,0,0,1.21192-.09961v3.124a8.46254,8.46254,0,0,1-2.49609.3711q-4.33887,0-4.42383-4.37988V92.17228h-2.25391V89.14689h2.25391V85.352Z"/>
            <path class="cls-2" d="M514.03613,104.87052a7.57839,7.57839,0,0,1-5.52929-2.083,7.40518,7.40518,0,0,1-2.13282-5.5498v-.40039a9.33038,9.33038,0,0,1,.89844-4.15821,6.5977,6.5977,0,0,1,2.54688-2.82519,7.14759,7.14759,0,0,1,3.75976-.99219,6.406,6.406,0,0,1,4.98633,1.99805,8.11474,8.11474,0,0,1,1.81934,5.66406v1.68359h-9.83008a3.82512,3.82512,0,0,0,1.20508,2.42578,3.64673,3.64673,0,0,0,2.54687.91309,4.448,4.448,0,0,0,3.72363-1.72656l2.02637,2.26855a6.19364,6.19364,0,0,1-2.51074,2.04785A8.24937,8.24937,0,0,1,514.03613,104.87052ZM513.56445,92.186a2.57588,2.57588,0,0,0-1.99023.832,4.30831,4.30831,0,0,0-.97754,2.37891H516.333v-.33008a3.07265,3.07265,0,0,0-.74219-2.12793A2.6582,2.6582,0,0,0,513.56445,92.186Z"/>
            <path class="cls-2" d="M531.40039,93.01407a11.19391,11.19391,0,0,0-1.48437-.11425q-2.33935,0-3.06739,1.584v10.10156h-4.123V89.14689h3.89453l.11426,1.84082a3.82067,3.82067,0,0,1,3.43847-2.126,4.33811,4.33811,0,0,1,1.28418.18555Z"/>
          </g>
        </g>
        <path class="cls-3" d="M208.47791,63.39533l.02111-5.676v-.483c.114-52.078-13.449-56.849-56.479-56.894l-53.781-.1L56.63.00037c-43.029-.049-56.6,4.692-56.6,56.77v.483l.00769,5.672H0v8.562c.076,52.079,13.657,56.8,56.687,56.687l63.919.276,31.077.068c43.029.207,56.621-4.485,56.811-56.563v-.483l.018-8.077ZM56.663,19.84937l41.578.243v-.001l53.706.1c33.938-.553,37.079,2.773,36.7,36.973v.474l-.02905,8.085.03772.00012-.01269,5.71393v.474c.021,4.01245-.00739,7.5846-.12262,10.79L146.842,40.78742l-.00006-.00006L79.26086,108.42078l-10.2099-.04455c5.70929-5.698,11.80494-11.77918,17.95307-17.91089q19.161-19.117,38.305-38.247l-24.984-24.98-75.3836,75.17859A16.31049,16.31049,0,0,1,21.64,95.97962L74.848,42.20539l-5.23205-5.221L20.16248,86.436c-.29279-4.17651-.35492-9.10242-.31147-14.95068v-6.1839l.042-.00006-.012-8.085v-.475C19.581,22.54139,22.725,19.22242,56.663,19.84937Zm122.45929,86.18756L143.608,70.5254l-15.11,15.02.001.001-23.09625,22.9884-9.83929-.0429L143.363,60.68439c11.97857,11.7912,28.72058,28.27722,41.21557,40.73993A12.91148,12.91148,0,0,1,179.12231,106.03693Z"/>
      </g>
    </g>
  </g>
</Logo>
</Link>
  )
}

export default SnowKingLogo
