
// Required for Prismic 

exports.linkResolver = (doc) => {
 
      if (doc.type === 'program_page') {
        return `/programs/${doc.uid}`
      }
      if (doc.type === 'page') {
        return `/${doc.uid}`
      }
      if (doc.type === 'events') {
        return `/events/${doc.uid}`
      }

      return '/'
    }
    