import React from "react";
import { Box, 
    Flex,
    Text
  } from "@chakra-ui/react";

import LogoBackground from "./LogoBackground";
import SnowKingLogo from "./SnowKingLogo";
import TopMenu from "./TopMenu";
import MainMenu from "./MainMenu";
import MobileMenu from "./MobileMenu";

const Header = () => {
  return (
    <Box as="header" w="100%" style={{ position: "absolute" }} zIndex="10">
      <Flex w="100%" flexDirection="row">
        <Box w={{base: '70%', md: '50%', lg: '30%'}} style={{ position: 'relative'}}>
          <Box style={{ position: 'absolute'}} width={{base: '100%', md: '100%', lg: '100%'}}>
            <LogoBackground width="100%" />
          </Box> 
          <Box style={{ position: 'absolute'}} w={{base: '55%', md: '60%', lg: '50%'}} ml={{base: '10%'}} mt={{base: '3%'}}>
              <SnowKingLogo 
                  width="100%"
              />
          </Box>
        </Box>
        <Flex w="50%" justifyContent="flex-end" mr="4" display={{base: 'flex', lg: 'none'}}>
            <MobileMenu />
        </Flex>
        <Box display={{base: 'none', lg: 'block'}} width="70%">
            <TopMenu />
            <MainMenu />
          </Box>
      </Flex>
    </Box>
  );
};

export default Header;
