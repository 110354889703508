import { extendTheme } from '@chakra-ui/react'
import { createBreakpoints } from '@chakra-ui/theme-tools'

const breakpoints = createBreakpoints({
    sm: "360px",
    md: "768px",
    lg: "960px",
    xl: "1200px",
    '2xl': '1535px',
});

const theme = { 
    config: {
        initialColorMode: "light",
        useSystemColorMode: false,
    },
    breakpoints,
    fonts: {
        body: "lato, sans-serif",
        heading: "abril fatface, serif"
    },
    colors: {
        brand: {
            '50':  '#fdfcfa',
          '100': '#fbf0ed',
          '200': '#f8cfda',
          '300': '#eea1b5',
          '400': '#eb718d',
          '500': '#de4e6b',
          '600': '#cc2027',
          '700': '#a02738',
          '800': '#741b25',
          '900': '#471114',
        },
        steel: {
            '50':  '#f4f8f9',
            '100': '#daf1fa',
            '200': '#afe2f4',
            '300': '#7ac4e3',
            '400': '#43a0cc',
            '500': '#317fb4',
            '600': '#29659b',
            '700': '#004f7e',
            '800': '#193356',
            '900': '#0f1f38',
          },
        shadow: {
            '50':  '#f8f9f7',
            '100': '#f2f2f2',
            '200': '#dfdadd',
            '300': '#bdb7ba',
            '400': '#978f92',
            '500': '#7a6f70',
            '600': '#645455',
            '700': '#4d3f41',
            '800': '#352c2e',
            '900': '#221c1e',
          },
},
    styles: {
        global: {
            a: {
                color: 'brand.600',
                _hover: {
                    textDecoration: 'none',
                    color: 'shadow.700'
                }
            },
            p: {
                marginBottom: '1.5rem'
            },
            h2: {
                fontSize: '2rem',
                fontWeight: '100',
                fontFamily: 'lato, sans-serif',
            },
            h3: {
                fontSize: '1.75rem',
                fontWeight: '100',
                fontFamily: 'abril fatface, serif',
            }
        }
    },
    components: {
        Text: {
            marginBottom: '1.5rem',
            variants: {
                "sub-head": {
                    color: 'steel.700',
                    fontSize: '1rem',
                    textTransform: 'uppercase'
                }
            }
        },
        Heading: {
          variants: {
                "page-title": {
                    fontSize: '4rem',
                    fontWeight: '600',
                    textAlign: 'center'
                },
                "sub-title": {
                    fontSize: '2rem',
                    fontWeight: '100',
                    fontFamily: 'lato, sans-serif',
                    textAlign: 'center'
                },
                "stat-number": {
                    fontFamily: 'lato, sans-serif',
                    textAlign: 'center',
                    fontWeight: '700'
                },
                "stat": {
                    fontFamily: 'lato, sans-serif',
                    textAlign: 'center',
                    fontWeight: '700',
                    textTransform: 'uppercase'
                }
            }
        },
        Link: {
            base: {
                color: 'brand.600',
                _hover: {
                    textDecoration: 'none',
                    color: 'shadow.600'
                }
            },
            variants: {
                "nav-item": {
                    color: "shadow.900",
                    fontSize: "1rem",
                    textTransform: "uppercase",
                    fontWeight: 700,
                    _hover: {
                        color: "brand.600",
                        textDecoration: "none"
                    },
                    active: {
                        color: "brand.600"
                    }
                },
                "top-nav-item": {
                    color: "shadow.900",
                    fontSize: "0.9rem",
                    fontWeight: 700,
                    _hover: {
                        color: "brand.600",
                        textDecoration: "none"
                    },
                },
                "footerNav": {
                    color: 'shadow.900',
                    fontWeight: 700,
                    textTransform: "uppercase",
                    marginBottom: '0.8rem',
                    _hover: {
                        textDecoration: 'none',
                        color: 'brand.600'
                    }
                },
                "tech-menu": {
                    color: "steel.700",
                    fontSize: '1.25rem',
                    _hover: {
                        textDecoration: 'none',
                        color: 'steel.500'
                    }
                }
            }
        },
        Button: {
            variants: {
                "red-button": {
                    backgroundColor: 'brand.600',
                    color: 'white',
                    borderRadius: '50',
                    _hover: {
                        backgroundColor: 'brand.500',
                        color: 'white'
                    }
                },
                "black-button": {
                    backgroundColor: 'shadow.900',
                    color: 'white',
                    borderRadius: '50',
                    _hover: {
                        backgroundColor: 'shadow.600',
                        color:'white'
                    },
                    _disabled: {
                        backgroundColor: 'shadow.300',
                    }
                }
            }
        },
        Tabs: {
            variants: {
                "verticalTabs": {
                    borderInlineStartWidth: '10px',
                    justifContent: "left" 
                }
            } 
        },
        Table: {
            variants: {
                "stats-table": {
                    th: {
                        fontSize: '1rem',
                        fontFamily: 'lato, sans-serif',
                    }
                }
            }
    }
    }
}

export default extendTheme(theme)