import React from 'react'
import styled from '@emotion/styled'

const Logo = styled.svg`
width: ${props => props.width ? props.width : '275px' };
height: 100%;
.cls-1 {
        fill: #cc2027;
      }
`

const LogoBackground = (props) => {
  return (
<Logo xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1059.13501 295.71132" {...props}>
  <g id="Layer_2" data-name="Layer 2">
    <g id="Layer_1-2" data-name="Layer 1">
      <g>
        <path class="cls-1" d="M.5,128.977V62.03027l-.5.00067v103.871C368.86511,362.73962,543.41876,279.05743,556.16,272.409,495.52411,295.619,318.47278,329.35431.5,128.977Z"/>
        <path class="cls-1" d="M577.60962,262.397,1012.308,60.61194l-64.65649.09064L580.3,260.725S579.40741,261.34259,577.60962,262.397Z"/>
        <path d="M556.91809,272.002s-.25452.14423-.75812.407a146.43793,146.43793,0,0,0,21.44965-10.012Z"/>
        <path d="M.5,0V128.977C318.47278,329.35431,495.52411,295.619,556.16,272.409c.5036-.26282.75812-.407.75812-.407l20.69153-9.60492c1.79779-1.05444,2.69037-1.672,2.69037-1.672L947.65155,60.70258,1059.135,0Z"/>
      </g>
    </g>
  </g>
</Logo>

  )
}

export default LogoBackground
