import React from 'react'
import {
    Box,
    Flex,
    Link
} from '@chakra-ui/react'
import { Link as GatsbyLink } from 'gatsby'
import BuyTicketsModal from '../MooseHockeyModals/BuyTicketsModal'
import WatchLiveModal from '../MooseHockeyModals/WatchLiveModal'

const TopMenu = () => {
    return (
        <Flex flexDirection="row" 
            justifyContent="flex-end"
            my="2"
            display={{base: 'none', lg: 'Flex'}}
            >
                <Box>
                <Flex w="100%"
                    justifyContent="space-around"
                    borderBottom="4px solid"
                    borderBottomColor="shadow.500" >
                {/* <Box p="3">
                <Link as={GatsbyLink} to="/covid-protocols" variant="top-nav-item">
                    COVID Protocols
                </Link>
            </Box> */}
            <Box p="3">
            <BuyTicketsModal />
            </Box>
            <Box p="3">
            <WatchLiveModal />
            </Box>
            <Box p="3">
            <Link as={GatsbyLink} to="/contact" variant="top-nav-item">
                    Contact Us
                </Link>
            </Box>
                </Flex>
                </Box>
        </Flex>
    )
}

export default TopMenu